export const environment = {
  production: false,
  enableAnalytics: false,
  hmr: false,
  locale: 'hr',
  api: {
    url: 'https://test.e-medikus.com/api/v1/',
    clientId: 2,
    clientScope: '*',
    grantType: 'password',
    clientSecret: 'tbqEGCHWluosMQP1jT7loRL84WkUyEKGPVZrfqCu',
  },
  sites: {
    root: 'https://test.e-medikus.com/',
    ordination: 'https://test.e-medikus.com//ordination',
    case: 'https://test.e-medikus.com/case/',
    admin: 'https://test.e-medikus.com/admin/',
  },
  reCaptcha: {
    url: 'https://www.google.com/recaptcha/api.js',
    siteKey: '6LcRb8AUAAAAAIIoTbnPNsNCJCSV6lIVea_OFzjG'
  }
};
